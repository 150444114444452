























import { Component, Vue, Prop } from "vue-property-decorator";
import axios, { resHandle } from "@/plugins/axios";
import { GetOptionList } from "@/request/yian";
@Component({})
export default class ObjectSelectSearch extends Vue {
  @Prop()
  private message: any;
  private resultOptions: any[] = [];
  @Prop()
  private type: any;
  @Prop()
  private pl: any;
  private messageChange() {
    this.$emit("update:message", this.message);
  }
  /**
   * @description 获取store中的可编辑状态
   */
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  /**
   * @description 搜索
   */
  private fetchOptions(ev: any) {
    const params: any = {
      params: {
        type: this.type,
        search: ev,
      },
    };
    GetOptionList(this, params).then((data: any) => {
      this.resultOptions = data;
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    if (this.message.value) {
      this.resultOptions.push(this.message.value);
      this.$forceUpdate();
    }
  }
}
