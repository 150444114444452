























import { Component, Vue, Prop } from "vue-property-decorator";
import { GetOptionList } from "@/request/yian";
@Component({})
export default class Name extends Vue {
  @Prop()
  private type: any;
  private data: any = [];
  private options: any = [];
  private search(val: any) {
    const params: any = {
      params: {
        search: val,
        type: this.type,
      },
    };
    GetOptionList(this, params).then((res: any) => {
      this.options = res;
    });
  }
  private change() {
    this.$emit("dataChange", this.data);
  }
  private mounted() {
    this.search("");
  }
}
