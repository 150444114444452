













































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetOptionList } from "@/request/yian";
@Component({})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    if (this.data) {
      this.value = JSON.parse(JSON.stringify(this.data));
    }
  }
  @Prop()
  private visible: any;
  @Watch("visible", { immediate: true })
  private visibleChange() {
    if (this.visible) {
      this.getMulu();
    }
  }
  private options: any = [];
  private mulu: any = [];
  private value: any = [];
  private cpart: any = "";
  private cancel() {
    this.$emit("cancel");
  }
  private confirm() {
    this.$emit("update", this.value);
  }
  private remoteMethod(e: any) {
    this.fetchOptions(e);
  }
  private getMulu() {
    const params: any = {
      params: {
        search: "",
        type: this.type,
      },
    };
    GetOptionList(this, params).then((res: any) => {
      this.mulu = res;
      if (this.mulu.length > 0) {
        this.cpart = this.mulu[0].name;
        this.mulu.forEach((element: any) => {
          element.children.forEach((ele: any) => {
            this.options.push(ele);
          });
        });
      }
    });
  }
  /**
   * @description 搜索
   */
  private fetchOptions(ev: any) {
    // const params: any = {
    //   params: {
    //     type: this.c.search,
    //     search: ev,
    //   },
    // };
    // GetTerms(this, params).then((data: any) => {
    //   this.content.value.forEach((element: any) => {
    //     const index = data.findIndex((val: any) => {
    //       return element.id === val.id;
    //     });
    //     if (index === -1) {
    //       data.push(element);
    //     }
    //   });
    //   this.options = data;
    // });
  }
  /**
   * @description 切换左边导航
   */
  private changePart(item: any, i: any) {
    const parant: any = document.getElementById("scrollBox");
    const children: any = document.getElementById("part" + i);
    if (parant) {
      if (children) {
        const parentTop = parant.offsetTop;
        const childTop = children.offsetTop;
        parant.scrollTop = childTop - parentTop - 14;
      }
    }
    setTimeout(() => {
      this.cpart = item.name;
    }, 50);
  }
  private checkChange(item: any) {
    const index = this.value.findIndex((v: any) => {
      return v._id === item._id;
    });
    if (index == -1) {
      this.value.push(item);
    } else {
      this.value.splice(index, 1);
    }
  }
  private ifSelected(item: any) {
    let result: any = false;
    const index = this.value.findIndex((v: any) => {
      return v._id === item._id;
    });
    if (index !== -1) {
      result = true;
    }
    return result;
  }
  private valChange(e: any) {
    this.mulu.forEach((items: any) => {
      items.children.forEach((item: any) => {
        item.value = false;
        e.forEach((ele: any) => {
          if (item.id === ele.id) {
            item.value = true;
          }
        });
      });
    });
  }
  private scrollEvent() {
    const parant = document.getElementById("scrollBox");
    if (parant) {
      const scrollTop = parant.scrollTop;
      const parentTop = parant.offsetTop + 14;
      this.mulu.forEach((ele: any, i: any) => {
        if (i < this.mulu.length-1) {
          const cuEle = document.getElementById("part" + i);
          const nextEle = document.getElementById("part" + (i + 1));
          if (cuEle && nextEle) {
            const cuTop = cuEle.offsetTop - parentTop;
            const nextTop = nextEle.offsetTop - parentTop;
            if (scrollTop >= cuTop && scrollTop < nextTop) {
              this.cpart = ele.name;
            }
          }
        } else {
          const cuEle = document.getElementById("part" + i);
          if (cuEle) {
            const cuTop = cuEle.offsetTop - parentTop;
            if (scrollTop >= cuTop) {
              this.cpart = ele.name;
            }
          }
        }
      });
    }
  }
  private mounted() {}
}
