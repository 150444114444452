









































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import LeftNav from "../../components/left-nav.vue";
import NavBox from "../../components/nav-box.vue";
import TopSearch from "../../components/top-search.vue";
import SearchDuoxuan from "../../components/search-duoxuan.vue";
import SelectDuoxuan from "../../components/select-duoxuan.vue";
import Zhongyaoyinpian from "../../components/zhongyaoyinpian.vue";
import { GetOptionList } from "@/request/yian";
@Component({
  components: {
    MainHeader,
    Empty,
    LeftNav,
    NavBox,
    TopSearch,
    SearchDuoxuan,
    SelectDuoxuan,
    Zhongyaoyinpian,
  },
})
export default class Name extends Vue {
  @Watch("jibing", { immediate: true })
  private jibingChange() {
    console.log("AI辅助决策疾病改变");
  }
  private ifShowImport: any = false;
  private importData: any = {};
  private chufangs: any = [];
  private visible: any = false;
  private cValue: any = [];
  private type: any = "";
  private zhengzhuangData: any = [];
  private zhengxingData: any = [];
  private chufangData: any = [];
  private data: any = {
    舌象: [],
    面象: [],
    脉象: [],
    中医证型: [],
    中医治法: [],
  };
  private get jibing() {
    return this.$store.state.jibing;
  }
  private dataChange(val: any, type: any) {
    this.data[type] = val;
  }
  private send() {
    console.log(this.data);
  }
  private updateYinpian(val: any) {
    this.data["中药饮片信息"] = val;
    this.$forceUpdate();
  }
  private openVisible(val: any) {
    this.type = val;
    this.cValue = this.data[val];
    this.visible = true;
  }
  private delOne(type: any, index: any) {
    this.data[type].splice(index, 1);
  }
  private getZhengzhuangData() {
    this.zhengzhuangData = [
      {
        name: "头晕",
        open: true,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
      {
        name: "气喘",
        open: false,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
      {
        name: "头痛",
        open: false,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
    ];
  }
  private getZhengxingData() {
    this.zhengxingData = [
      {
        name: "血瘀证",
        open: false,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
      {
        name: "血燥证",
        open: false,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
      {
        name: "阴虚证",
        open: false,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
    ];
  }
  private getChufangData() {
    this.chufangData = [
      {
        name: "葛根桂枝汤",
        open: false,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
      {
        name: "麻黄桂枝各半汤",
        open: false,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
      {
        name: "小青龙汤",
        open: false,
        data: [
          {
            来源: "中医诊断学",
            简介: "是指患者自觉视物昏花旋转，轻者闭目可缓解，重者感觉天旋地转，不能站立，闭目亦不能缓解。因外邪侵入或脏腑功能失调引起经络阻滞，清阳之气不升或风火上扰。",
          },
          {
            来源: "中国成人血脂异常防治指南（2016版）",
            简介: "因邪热劫阴，或肝肾阴亏，风轮失养所致。临床以黑睛失却光泽，起细小星翳，干涩不爽，畏光、眨眼，口干、咽燥，舌质红，舌苔少，脉细数等为特征的证候。",
          },
        ],
      },
    ];
  }
  private selectTrue(val: any) {
    this.data[this.type] = val;
    this.visible = false;
  }
  private openImport() {
    this.importData = {};
    this.ifShowImport = true;
  }
  private importChufang() {}
  private focus(e: any) {
    if (e) {
      this.remoteMethod("");
    }
  }
  private remoteMethod(e: any) {
    const params: any = {
      params: {
        search: "",
        type: '方剂',
      },
    };
    GetOptionList(this, params).then((res: any) => {
      this.chufangs = res;
       
    });
  }
  private mounted() {
    this.getZhengzhuangData();
    this.getZhengxingData();
    this.getChufangData();
  }
}
